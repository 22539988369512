<template>
  <div class="fill-height">
    <v-layout align-center justify-center class="fill-height">
      <v-card class="mx-auto" max-width="500">
        <v-img
          class="white--text align-end"
          height="150px"
          src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        >
          <v-card-title>Company : {{ "SilcBio" }}</v-card-title>
        </v-img>

        <v-card-subtitle class="pb-0"> My Profile </v-card-subtitle>

        <v-card-text class="text--primary">
          <v-list class="transparent">
            <v-list-item v-for="item in items" :key="item.name">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle class="text-right">
                {{ item.info }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn color="orange" text> Share </v-btn>
        <v-btn color="orange" text> Explore </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-layout>

    <!-- <div id="top-header">
      <div class="title">
        <i class="el-icon-s-grid"></i>
        <span>My Page</span>
      </div>

      <div class="">
        <el-card class="box-card">
        <div slot="header" class="clearfix">
            <span>Company : {{'SilcBio'}}</span>
        </div>
        <el-row>
            <el-col :span="8"><div class="grid-content bg-purple-dark">User ID</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple-dark">{{user_info.username}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="8"><div class="grid-content bg-purple-dark">User Name</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple-dark">{{user_info.real_name}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="8"><div class="grid-content bg-purple-dark">E-mail</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple-dark">{{user_info.email}}</div></el-col>
        </el-row>
        <el-row>
            <el-col :span="8"><div class="grid-content bg-purple-dark">Phone</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple-dark">{{user_info.phone}}</div></el-col>
        </el-row>
        </el-card>
      </div>
    </div> -->
  </div>
</template>

<script>
// import { mapMutations, mapState, mapGetters } from "vuex";
// import store from "@/store";

export default {
  name: "my-page",
  // components: { store },
  computed: {
    // ...mapState(["user_info"]),
    items: {
      get() {
        return [
          {
            name: "User ID",
            info: this.$store.state.user_info.username,
          },
          {
            name: "User Name",
            info: this.$store.state.user_info.real_name,
          },
          {
            name: "E-mail",
            info: this.$store.state.user_info.email,
          },
          {
            name: "Phone",
            info: this.$store.state.user_info.phone,
          },
        ];
      },
    },
  },
  created() {
    this.$debug(this.$store);
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.el-row {
  height: 50px;
  padding-left: 60px;
}
</style>
